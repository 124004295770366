body {
  background: #f0efeb;
  color: #333;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}

@mixin fade-in($seconds) {
  -webkit-animation: fadein #{$seconds}s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein #{$seconds}s; /* Firefox < 16 */
  -ms-animation: fadein #{$seconds}s; /* Internet Explorer */
  -o-animation: fadein #{$seconds}s; /* Opera < 12.1 */
  animation: fadein #{$seconds}s;
}

.section {
  @include fade-in(2);

  padding: 14% 7%;
  max-width: 750px;
}

.section {
  &__greeting {
    margin: 0;
    font-size: 20px;
  }

  &__header {
    font-size: 44px;
    margin: 0;
  }

  &__title {
    font-size: 22px;
    margin: 10px 0 0;
  }

  &__description {
    font-size: 20px;
  }

  &__links {
    margin:0;
    padding: 15px 0 0;
    display: flex;
  }

  &__link {
    list-style-type: none;
    padding: 0 10px;
  }
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
